<template>
    <div class="row">
        <label class="col-sm-3 col-form-label required-label">{{ $t('overview.type') }}</label>
        <fieldset class="btn-group col-sm-9">
            <input id='type-1' type="radio" name="type" class="btn-check" :value="0" v-model="template.is_appendage" />
            <label for="type-1" class="btn btn-sm">{{$t('navigation.templates')}}</label>
            <input id='type-2' type="radio" name="type" class="btn-check" :value="1" v-model="template.is_appendage" />
            <label for="type-2" class="btn btn-sm">{{$t('navigation.attachments')}}</label>
        </fieldset>
    </div>
    <div class="row">
        <label for="name" class="col-sm-3 col-form-label required-label">{{$t('overview.name')}}</label>
        <div class="col-sm-9">
            <input v-model="template.name" type="text" id="name" />
        </div>
    </div>
    <div class="row">
    <div>
        ##werkgever_naam##  -  
        ##werkgever_adres##  -  
        ##werkgever_postcode##  -  
        ##werkgever_plaats##  -  
        ##werkgever_vertegenwoordiger_naam##  -  
        ##werknemer_voornaam##  -  
        ##werknemer_voorletters##  -  
        ##werknemer_voorvoegsel##  -  
        ##werknemer_achternaam##  -  
        ##werknemer_straat##  -  
        ##werknemer_huisnr##  -  
        ##werknemer_toevoeg##  -  
        ##werknemer_postcode##  -  
        ##werknemer_plaats##  -  
        ##werknemer_geboortedatum##  -  
        ##werknemer_geboorteplaats##  -  
        ##werknemer_geboorteland##  -  
        ##werknemer_land##  -  
        ##startdatum##  -  
        ##einddatum##  -  
        ##uren##  -  
        ##proeftijd_maand##  -  
        ##functie_naam##  -  
        ##referentienummer##  -  
        ##salaris##  -  
        ##salaris_per##  -  
        ##artikel##  -  
        ##onkosten##  -  
        ##reiskosten##  -  
        ##telefoon##  -  
        ##contract_plaats##  -  
        ##contract_datum##  -  
        ##werknemer_iban##  -  
        ##werknemer_email##  -  
        ##werknemer_bsnnummer##  -  
        ##werknemer_telefoonnr##  -  
        ##contracttype## Onbepaalde 
        ##tijd## Bepaalde 
        ##tijd##  -  
        ##vakkracht##  -  
        ##ja##  -  
        ##nee##  -  
        ##dienstverband##  -  
        ##Fulltime##  -  
        ##Parttime##  -  
        ##proeftijd##  -  
        ##loonheffingskorting##  -  
        ##handtekening_werkgever##  -  
        ##handtekening_werknemer##  -  
        ##nieuwe pagina## 
    </div>
    </div>
    <div class="row">
        <label for="contents" class="col-sm-3 col-form-label required-label">{{$t('overview.contents')}}</label>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <textarea v-model="template.contents" id="contents" :rows="30"></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TemplateForm',
        props: ['itemData'],
        data() {
            return {
                template: {
                    'name': '',
                    'contents': '',
                    'is_appendage': null,
                    'order': 1,
                    'companies': []
                },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.template = val    
            },
        },
        methods: {
            validate(){
                let template = this.template;
                return template.name && template.is_appendage != null && template.contents;
            },
            getData() {
                return this.template;
            },
            setData(data) {
                this.template = data
            },
            reset() {
                this.template = {
                    'name': '',
                    'contents': '',
                    'is_appendage': null,
                    'order': 1,
                    'companies': []
                }
                this.loading = false
                this.invalid = {}
            },
            // autoRows(content){
            //     if(!content) return 30;
            //     const rows = content.split('\n').length;
            //     return Math.min(30, rows);
            // }
        },
        mounted () {
            this.reset()
           
        }
    }
</script>
